<template>
  <button class="focus:outline-none">
    <svg
      class="close-svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0005 1.32238L18.6781 0L10.0005 8.67949L1.32286 0L0.000488281 1.32238L8.67998 10L0.000488281 18.6776L1.32286 20L10.0005 11.3205L18.6781 20L20.0005 18.6776L11.321 10L20.0005 1.32238Z"
        :fill="color ? color : 'rgba(0, 0, 0, 0.25)'"
      />
    </svg>
  </button>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
  },
};
</script>
<style lang="css">
.close-svg path {
  stroke: rgba(255, 255, 255, 0.8);
  stroke-width: 1px;
}
</style>
